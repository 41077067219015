import React from "react";
import { Link } from "react-router-dom";

const organizers = [
  {
    name: 'Viraj Kale',
    position: 'Event Management',
    image: require('../../assets/images/Viraj Kale.png'),
  },

  {
    name: 'Taha Shaikh',
    position: 'Technical Head',
    image: require('../../assets/images/Taha Shaikh.png'),
  },

  {
    name: 'Gautam Patil',
    position: 'Sponsorship',
    image: require('../../assets/images/Gautam Patil.png'),
  },

  {
    name: 'Yash Nandode',
    position: 'Infrastructure and Photography',
    image: require('../../assets/images/Yash Nandode.png'),
  },

  {
    name: 'Yash Shah',
    position: 'Treasurer',
    image: require('../../assets/images/Yash Shah.png'),
  },

  {
    name: 'Nikhil Patil',
    position: 'Campaigning',
    image: require('../../assets/images/Nikhil Patil.png'),
  },

  {
    name: 'Ruturaj Patil',
    position: 'Marketing and PR',
    image: require('../../assets/images/Ruturaj Patil.png'),
  },

  {
    name: 'Sai Patil',
    position: 'Registration',
    image: require('../../assets/images/Sai Patil.png'),
  },

  {
    name: 'Bhavesh Raj Singh',
    position: 'Design and Print Media',
    image: require('../../assets/images/Bhavesh Raj Singh.png'),
  },

  {
    name: 'Atharva Saoji',
    position: 'Food and Decorations',
    image: require('../../assets/images/Atharva Saoji.png'),
  },

  {
    name: 'Shree Patra',
    position: 'Stage and Content Writing',
    image: require('../../assets/images/Shree Patra.png'),
  }
]

class SpeakersThree extends React.Component {
  render() {
    return (
      <section className="speakers-area-two ptb-120">
        <div className="container">
          <div className="row" style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto"
          }}>
            <div className="col-lg-4 col-md-6">
              <div className="single-speakers-box">
                <div className="speakers-image">
                  <img
                    src={require("../../assets/images/sir-1.png")}
                    alt="speakers"
                  />
                </div>

                <div className="speakers-content"
                    style={{
                        background: "#0E5BAD",
                        color: "#fff"
                    }}
                >
                  <h3>
                    <Link to="#">Prof. Dr. Sunil S.Pimplikar</Link>
                  </h3>
                  <span>CONVENOR</span>

                  <ul className="social">
                    <li>
                      <Link to="https://www.linkedin.com/" target="_blank">
                        <i className="icofont-linkedin"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="single-speakers-box">
                <div className="speakers-image">
                  <img
                    src={require("../../assets/images/sir-2.png")}
                    alt="speakers"
                  />
                </div>

                <div className="speakers-content"
                    style={{
                        background: "#0E5BAD",
                        color: "#fff"
                    }}
                >
                  <h3>
                    <Link to="#">Prof. Pravin Minde</Link>
                  </h3>
                  <span>CO-CONVENOR</span>

                  <ul className="social">
                    <li>
                      <Link to="https://www.linkedin.com/" target="_blank">
                        <i className="icofont-linkedin"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {organizers.map((index) => (
              <div className="col-lg-4 col-md-6">
              <div className="single-speakers-box">
                <div className="speakers-image">
                  <img
                    src={index.image}
                    alt="speakers"
                  />
                </div>

                <div className="speakers-content"
                    style={{
                        background: "#0E5BAD",
                        color: "#fff"
                    }}
                >
                  <h3>
                    <Link to="#">{index.name}</Link>
                  </h3>
                  <span>{index.position}</span>

                  <ul className="social">
                    <li>
                      <Link to="https://www.linkedin.com/" target="_blank">
                        <i className="icofont-linkedin"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            ))}
          </div>

        </div>
      </section>
    );
  }
}

export default SpeakersThree;
