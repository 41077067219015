import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Common/Footer";

const events = [
  // {
  //   name: "AutoCAD Workshop",
  //   desc: "Blueprints×Nirmitee brings to every student an opportunity to join us for an interactive and engaging software workshop that will equip you with the tools and knowledge you need to succeed in the construction industry. Our workshop will be led by experienced professionals who are passionate about sharing their expertise and helping you reach your full potential.",
  //   img : "Softcon",
  //   type: "Online",
  //   date : 'TBD',
  //   time : 'TBD'
  // },

  // {
  //   name: "Revit Workshop",
  //   desc: "Blueprints×Nirmitee brings to every student an opportunity to join us for an interactive and engaging software workshop that will equip you with the tools and knowledge you need to succeed in the construction industry. Our workshop will be led by experienced professionals who are passionate about sharing their expertise and helping you reach your full potential.",
  //   img : "Softcon",
  //   type: "Online",
  //   date : 'TBD',
  //   time : 'TBD'
  // },

  // {
  //   name: "Microsoft Projects",
  //   desc: "Blueprints×Nirmitee brings to every student an opportunity to join us for an interactive and engaging software workshop that will equip you with the tools and knowledge you need to succeed in the construction industry. Our workshop will be led by experienced professionals who are passionate about sharing their expertise and helping you reach your full potential.",
  //   img : "Softcon",
  //   type: "Online",
  //   date : 'TBD',
  //   time : 'TBD'
  // },
  {
    name: "Workshop on Industrial Training",
    desc: "This workshop which is for those Students who desire to read, interprete and apply from the project Architectural ,RCC, Electrical and Plumbing drawings Estate of the Real Building Construction Commercid project; based on the requirements. of these drawings from the practical Perspective. Drawings are the heart of and the languages for engineering Communication in all phases of any Construction project right from design, tendering, Construction, operation 8 maintenance phase The workshop is of any overall 8 hours duration splitt 4 hours 13th and 14th March Per day on It incluster trations based interaction working from a construction professional who is very experienced hands on emperience with the project drawing Check lis Theven. lists tasks given if the aforementioned drawings will for the af foreme be discussed; importan importance of precision in drawings highlighted. ensuring the will be In essence READINESS of a CIVIL ENGINEER for UTILISING DRAWINGS as needed in INDUSTRY for various purposes in the Motto of this workshop. There is are no limited Cum There on first entry fee entries, based basis; abo to receive the participation certificate the student needs to attend both the sessions; as well as Complete the group tasks assigned using the check lists made. The trainer and the organising Committee volunteers will facilitate the students in this endeavour.",
    img: "Softcon",
    type: "Offline",
    coordinator: "Kaushal Ghanwat",
    number: "8600176529",
    pdf : "https://drive.google.com/file/d/1pYZ3im-iV0mAED75JtjNq6YWEZep_tmJ/view?usp=sharing",
    date: "TBD",
    time: "TBD",
  },
  {
    name: "Contracts management training",
    desc: "It is a comprehensive training program designed to equip participants with the essential skills and knowledge required for effective contracts management in the construction industry. The program aims to empower participants to navigate the complexities of contractual agreements, mitigate risks, and optimize project outcomes through proficient contract management practices.",
    img: "Softcon",
    type: "Offline",
    coordinator: "Siddhi Dombe",
    number: "9579693609",
    date: "24th March 2025",
    time: "TBD",
  },
];

class Workshop extends React.Component {
  industrialWorkshop = () => {
    window.open("https://forms.gle/BkMyU7yznYjapyG39");
  };

  contractsWorkshop = () => {
    window.open("https://forms.gle/oYDmTBcJDyQw4P9U9");
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg2">
          <div className="container">
            <h1>Workshops</h1>
            <span>Take a look at all the Workshops</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Workshops</li>
            </ul>
          </div>
        </div>

        <section className="blog-area ptb-120 bg-image">
          <div className="container">
            <div className="row">
              {events.map((event) => (
                <div className="col-lg-5 col-md-6">
                  <div className="single-blog-post">
                    <div
                      className="blog-image"
                      style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Link to="#">
                        <img
                          src={require(`../../assets/images/${event.img}.png`)}
                          alt="event"
                          height={150}
                          width={150}
                          style={{
                            marginTop: "20px",
                            marginBottom: "20px",
                          }}
                        />
                      </Link>

                      <div className="post-tag">
                        <Link to="#">{event.type}</Link>
                      </div>
                    </div>

                    <div className="blog-post-content">
                      <h3>
                        <Link to="#">{event.name}</Link>
                      </h3>
                      <p>{event.desc}</p>
                      <p>
                        <b>Event Coordinators : {event.coordinator}</b>
                      </p>
                      <p>
                        <b>Contact Numbers : {event.number}</b>
                      </p>
                      <p>
                        <b>Date : {event.date}</b>
                      </p>
                      {/* {event.pdf && (
                        <Link
                        to="#"
                        className="read-more-btn"
                        onClick={()=>{
                          window.open(event.pdf);
                        }}
                        >
                          Training Details <i className="icofont-double-right"></i>
                        </Link>
                      )} <br/><br/> */}
                      <Link
                        to="#"
                        className="read-more-btn"
                        onClick={() => {
                          if (event.name === "Workshop on Industrial Training")
                            this.industrialWorkshop();
                          else if (
                            event.name === "Contracts management training"
                          )
                            this.contractsWorkshop();
                        }}
                      >
                        Register Now <i className="icofont-double-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Workshop;
