import React from "react";
import { Link } from "react-router-dom";
import "isomorphic-fetch";
import Footer from "../Common/Footer";

class Contact extends React.Component {
  linkedin = () => {
    window.open("https://www.linkedin.com/company/mitwpunirmitee");
  };

  facebook = () => {
    window.open(" https://www.facebook.com/mitwpunirmitee");
  };

  instagram = () => {
    window.open("  https://www.instagram.com/mitwpunirmitee/?hl=en ");
  };

  youtube = () => {
    window.open("   https://www.youtube.com/channel/UC-aSY-unwdIRmwpMfRoPjNA ");
  };

  render() {
    return (
      <React.Fragment>
        <div className="page-title-area item-bg1">
          <div className="container">
            <h1>Contact Us</h1>
            <span>Reach out to us for any queries</span>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>Contact</li>
            </ul>
          </div>
        </div>

        <section className="contact-area ptb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-phone"></i>
                  </div>

                  <div className="content">
                    <h4>Phone</h4>
                    <p>Pranav Nagare- 9356863109</p>
                    <p>Piyush Patil- 8888826155</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-email"></i>
                  </div>

                  <div className="content">
                    <h4>E-mail</h4>
                    <p>nirmitee@mitwpu.edu.in</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                <div className="contact-box">
                  <div className="icon">
                    <i className="icofont-world"></i>
                  </div>

                  <div className="content">
                    <h4>Location</h4>
                    <p>
                      MIT World Peace University, Survey No, 124, Paud Rd,
                      Kothrud, Pune, Maharashtra 411038
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row h-100 align-items-center contact-form">
              <div
                className="col-lg-4 col-md-6"
                style={{
                  marginRight: "420px",
                }}
              >
                <div
                  className="leave-your-message"
                  style={{
                    textAlign: "left",
                  }}
                >
                  <h3>Leave Your Message</h3>
                  <p>
                    If you have any questions about our event , feel free to
                    contact us on the number given or on our official email
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="leave-your-message">
                  <div className="stay-connected">
                    <h3>Stay Connected</h3>
                    <ul
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        listStyle: "none",
                        "@media (max-width: 768px)": {
                          display: "inline-block",
                        },
                      }}
                    >
                      <li>
                        <Link to=" #" onClick={this.facebook}>
                          <i className="icofont-facebook"></i>
                          <span>Facebook</span>
                        </Link>
                      </li>

                      <li>
                        <Link to="#" onClick={this.youtube}>
                          <i className="icofont-youtube-play"></i>
                          <span>Youtube</span>
                        </Link>
                      </li>

                      <li>
                        <Link to="#" onClick={this.instagram}>
                          <i className="icofont-instagram"></i>
                          <span>Instagram</span>
                        </Link>
                      </li>

                      <li>
                        <Link to="#" onClick={this.linkedin}>
                          <i className="icofont-linkedin"></i>
                          <span>Linkedin</span>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Contact;
